import { Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { WfHeading } from "src/components/base/wf-heading";
import { WikifolioInfoCard } from "src/components/modules/components/wikifolio-info-card/wikifolio-info-card";
import { ISelectedWikifolioData } from "src/components/modules/selected-wikifolios/selected-wikifolios-module.types";

interface IVotingTopTradersProps {
    topTraderWikifolios: ReadonlyArray<ISelectedWikifolioData & { traderChoice: string }> | null;
    topTradersHaveVotedLikeUser: boolean | null;
}

export const VotingTopTraders = ({ topTraderWikifolios, topTradersHaveVotedLikeUser }: IVotingTopTradersProps) => {
    const { t } = useTranslation("voting-module");
    const topTradersHeading = t(topTradersHaveVotedLikeUser ? "top-traders-voted-the-same" : "top-traders-voted");

    return topTraderWikifolios?.length ? (
        <>
            <WfHeading as="h3" fontSize={["md", "xl"]} pt={4} pb={2}>
                {topTradersHeading}
            </WfHeading>
            <Stack direction={["column", "column", "row"]} spacing={[1, 3]} pb={[1]} align="flex-start">
                {topTraderWikifolios?.map(({ symbol, trader, ranking, shortDescription }) => (
                    <WikifolioInfoCard
                        key={symbol}
                        flex={1}
                        minW={["100%", "100%", "293px"]}
                        maxW={["296px", "296px", "296px", "358px"]}
                        trader={trader}
                        ranking={ranking}
                        symbol={symbol}
                        shortDescription={shortDescription}
                    />
                ))}
            </Stack>
        </>
    ) : null;
};
