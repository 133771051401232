import { Box, FlexProps, Text } from "@chakra-ui/react";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { HoverCard } from "src/components/common/hover-card";
import { Ranking } from "src/components/common/ranking/ranking";
import { IRankingData } from "src/components/common/ranking/types";
import { TraderAvatar } from "src/components/common/trader-avatar";
import { useGetUserName } from "src/hooks/use-get-user-name";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { ITrader, IUser, IWikifolio } from "src/types/common";

type IUserOrTrader = Pick<IUser, "id" | "firstName" | "lastName" | "nickName" | "isDeleted"> | ITrader;

export interface IWikifolioInfoCardProps extends Pick<IWikifolio, "symbol" | "shortDescription"> {
    trader: IUserOrTrader;
    ranking: IRankingData;
    hidePoints?: boolean;
}

/**
 * WikifolioInfoCard component is responsible for displaying information about a single Wikifolio.
 * It takes various props to render details such as the Wikifolio's name, symbol, performance, and other relevant data.
 * The component is designed to be used within a list or grid of Wikifolio cards, providing a consistent and informative
 * presentation of each Wikifolio's key information.
 */
export const WikifolioInfoCard = ({ trader, ranking, symbol, hidePoints, shortDescription, ...flexProps }: IWikifolioInfoCardProps & FlexProps) => {
    const { buildWikifolioLink } = useLinkBuilder();
    const getUserName = useGetUserName();

    return (
        <HoverCard
            as={WfLinkUnstyled}
            variant="unstyled"
            href={buildWikifolioLink(symbol)}
            {...flexProps}
            boxShadow="xs"
            _hover={{ transform: "scale(1.02)", zIndex: 1 }}
        >
            <TraderAvatar flexShrink={0} size="sm" trader={trader} mr={1} />
            <Box flexGrow={1} mr={1} fontSize="sm">
                <Text noOfLines={1} wordBreak="break-all" fontWeight="bold">
                    {shortDescription}
                </Text>
                <Text noOfLines={1} wordBreak="break-all">
                    {getUserName(trader)}
                </Text>
            </Box>
            <Ranking flexShrink={0} align="flex-end" size="xs" data={ranking} labelProps={{ fontSize: "sm" }} hidePoints={hidePoints} />
        </HoverCard>
    );
};
